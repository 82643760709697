<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">FORENSIC EVIDENCE IDENTIFICATION</p>
        </div>
        <div class="space_content">
            法医物证鉴定是指鉴定人运用法医物证学的科学技术或者专门知识，对各类生物检材进行鉴别和判断并提供鉴定意见的活动。法医物证鉴定包括个体识别，三联体亲子关系鉴定，二联体亲子关系鉴定，亲缘关系鉴定，生物检材种属和组织来源鉴定，生物检材来源生物地理溯源，生物检材来源个体表型推断，生物检材来源个体年龄推断以及与非人源生物检材相关的其他法医物证鉴定等。
        </div>
        <img class="img" style="width:100%" v-lazy="require('../../assets/range/1.jpg')">
        <div class="pathology_title">
            亲子鉴定
        </div>
        <div class="space_content">
            利用DNA分型技术，并根据有关的遗传学原理来评判被鉴定的个体与孩子之间是否存在亲生的父母子、父子或母子关系。一般亲子鉴定包括以下情况：
        </div>
        <div class="space_content" style="margin-bottom:2rem;text-indent:0rem;margin-left:2rem;">
            1. 怀疑子女不是亲生；<br/>2. 上户口、户口申报；<br/>3. 怀疑出生时调错婴儿；<br/>4. 失散家庭成员相认；<br/>5. 非婚生子女确认；<br/>6. 离婚、财产继承亲子鉴定；<br/>7. 移民要求出具亲生关系证明；<br/>8. 涉及计划生育超生；<br/>9. 无名尸认领和灾难性事故遇难人员身份确认等。
        </div>
        <div class="pathology_title">
            个体识别
        </div>
        <div class="space_content" >
            判断生物性斑痕检材的来源，或是否与某个体是同一人。
        </div>
        <div class="space_content" >
            个人识别是以同一认定理论为指导原则，通过对物证检材的遗传标记做出科学鉴定，依据个体特征来判断前后两次或多次出现的物证检材是否同属一个个体。
        </div>
        <div class="space_content" >
            生物性检材包括血液、血迹、精液、精斑、毛发、唾液斑、肌肉、骨骼、牙齿、软组织等。
        </div>
        <div class="space_content" style="margin-bottom:2rem;text-indent:0rem;margin-left:2rem;">
            1. 刑事案件现场遗留检材与犯罪嫌疑人同一鉴定；<br/>2. 交通事故现场遗留检材与肇事嫌疑人同一鉴定；<br/>3. 交通事故嫌疑车辆上的检材与被害人同一鉴定；<br/>4. 家庭中床单、内裤、纸巾等物品上的血斑、精斑与嫌疑人同一鉴定；<br/>5. 医疗纠纷中病理学蜡块、流产组织等与患者同一鉴定；<br/>6. 无名尸与身份认定等。
        </div>
        <div class="pathology_title">
            亲缘关系鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            非亲子鉴定的亲权鉴定，如同胞鉴定等，由于属非直接遗传，与亲子鉴定相比，鉴定的难度较大、准确性不如亲子鉴定。因此，只有在无法通过亲子鉴定达到委托人目的的情况下，才选择进行这类鉴定。多见于无法获得父母的生物性检材，而出于认亲、财产继承等目的，只有通过与争议父（母）的孩子、争议父（母）的父母、争议父（母）的同胞进行鉴定，对应为同胞鉴定、祖孙鉴定和叔侄鉴定等。
        </div>
        <div class="pathology_title">
            人体功能评定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            在凶杀、斗殴、碎尸、交通事故及强奸等案件中，在现场、被害人或加害人身上遗留的血痕、精斑、唾液斑、组织块、毛发及骨骼等生物性检材的检验及鉴定。
        </div>
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
}
</script>